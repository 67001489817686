import postCardComp from './components/postCardComp.vue';
import PostCard from "./components/postCard.vue";
import titleAssembly from './components/titleAssembly';
import richAssembly from './components/richAssembly';
import pictureAssembly from './components/pictureAssembly';
import graphAssembly from './components/graphAssembly';
import atlasAssembly from './components/atlasAssembly';
import videoAssembly from './components/videoAssembly';
import telephoneAssembly from './components/telephoneAssembly';
import qrcodeAssembly from './components/qrcodeAssembly';
import formAssembly from './components/formAssembly';
import comPopup from './components/popup';
import popupPicture from './components/popupPicture';
import logic from './components/logicNew';
import orderModular from './components/order';
import obtainingCustomersAssembly from './components/obtainingCustomers';
import principalbly from './components/principalbly';
// 接口
import * as apis from '@/api/land';
import html2canvas from 'html2canvas';
import { getAlipayMiniUrl } from "@/api/land";
export default {
  components: {
    postCardComp,
    PostCard,
    titleAssembly,
    richAssembly,
    pictureAssembly,
    graphAssembly,
    atlasAssembly,
    videoAssembly,
    telephoneAssembly,
    qrcodeAssembly,
    formAssembly,
    comPopup,
    popupPicture,
    logic,
    orderModular,
    obtainingCustomersAssembly,
    principalbly
  },
  data() {
    return {
      isBlackShow: false,
      isBlackInputShow: false,
      isMobileShow: false,
      mobileText: '',
      currentRow: {},
      principalObj: null,
      showPostCard: false,
      access_limit: '',
      add_limit: '',
      urls: '',
      sethref: '',
      setting: '',
      strategyLists: '',
      strategy_enter: [],
      contents: [],
      popcontent: '',
      popsetting: '',
      id: '',
      token: 'nbhIWVyw',
      ad_id: '',
      // 对应 URL 参数：adid
      creative_id: '',
      // 对应 URL 参数：creativeid
      creative_type: '',
      // 对应 URL 参数：creativetype
      click_id: '',
      // 对应 URL 参数：clickid
      source: '',
      // 对应 URL 参数：source
      cache: '',
      // 对应 URL 参数：cache
      channel_param: '45EM',
      // 对应 URL 参数：_cl
      user_wechat_id: '',
      // 用户微信id（如果页面有二维码，则需必填）
      wechat_package_id: '',
      //微信包id
      uid: '',
      //	用户id （存浏览器本地）
      visitContent: '',
      tid: '',
      tids: '',
      //用于source=='official_kf'专用，直接获取路由地址上的tid
      landingPageId: 0,
      pageType: true,
      biz: '',
      key: '',
      loop: '',
      modelType: '',
      isIframe: false,
      onLoad: 0,
      traceTimer: '',
      longTime: '',
      hide_long_time: 0,
      paymentDataUrl: '',
      dia: 1,
      startTime: 0,
      isLongPress: 0,
      longTimeNum: 0,
      hiddenStartTime: 0,
      trace40: 0,
      trace41: 0,
      urlParams: null,
      ibrand_id: 0,
      jump_token: '',
      linkUrl: ''
    };
  },
  watch: {
    onLoad() {
      if (this.onLoad === 2) {
        if (!this.pageType && this.modelType === 'ios') {
          this.$nextTick(() => {
            // this.html2canvas2();
          });
        }
      }
    }
  },
  created() {
    this.getModel();
    this.init();
    clearTimeout(this.traceTimer);
  },
  mounted() {
    // 监听浏览器返回
    // if (window.history && window.history.pushState) {
    //   history.pushState(null, null, document.URL)
    //   window.addEventListener('popstate', this.disableBrowserBack, false)
    // }
    window.onresize = () => {
      return (() => {
        // 基准大小
        const baseSize = 16;
        window.screenWidth = document.documentElement.clientWidth;
        // 当前页面宽度相对于 376 宽的缩放比例，可根据自己需要修改。
        const scale = window.screenWidth / 376;
        document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
      })();
    };
    document.addEventListener('visibilitychange', this.handleVisiable);
  },
  destroyed() {
    document.removeEventListener('visibilitychange', this.handleVisiable);
    // window.removeEventListener('popstate', this.disableBrowserBack, false);
  },

  methods: {
    mobileChange() {
      var phoneNumberRegex = /^1[3456789]\d{9}$/;
      if (this.mobile == '') {
        this.isMobileShow = true;
        this.mobileText = '手机号不可为空';
      } else if (!phoneNumberRegex.test(this.mobile)) {
        this.isMobileShow = true;
        this.mobileText = '手机号码格式不正确';
      } else {
        this.isMobileShow = false;
      }
    },
    save() {
      var phoneNumberRegex = /^1[3456789]\d{9}$/;
      if (this.mobile == '') {
        this.isMobileShow = true;
        this.mobileText = '手机号不可为空';
        return;
      } else if (!phoneNumberRegex.test(this.mobile)) {
        this.isMobileShow = true;
        this.mobileText = '手机号码格式不正确';
        return;
      }
      this.isBlackInputShow = false;
      let parse = {
        tid: this.tid,
        name: this.name,
        mobile: this.mobile
      };
      apis.saveBlackCustomer(parse).then(res => {
        alert('提交成功');
      });
    },
    // 离开页面执行
    // disableBrowserBack() {
    //   if (this.strategyLists.length >= 1) {
    //       this.handleTactics(this.strategyLists,'1')
    //   }
    // },
    diaChange(val) {
      this.dia = val;
    },
    paymentDataUrlChange(val) {
      this.paymentDataUrl = val;
    },
    longEnd(e) {
      this.isLongPress = e.isLongPress;
      this.longTimeNum = e.longTimeNum;
    },
    handleVisiable(e) {
      switch (e.target.visibilityState) {
        case 'prerender':
          break;
        case 'hidden':
          this.hiddenStartTime = new Date().getTime();
          // this.traceTimer = setTimeout(() => {
          //   if (
          //     this.isWeixin() &&
          //     !this.pageType &&
          //     this.wechat_package_id > 0 &&
          //     this.isLongPress === 1 &&
          //     this.trace41 === 0
          //   ) {
          //     this.trace41 = 1;
          //     this.trace('41');
          //   }
          // }, this.hide_long_time);
          if (this.isLongPress === 1) {
            this.trace('41');
            if (!this.pageType && this.modelType !== 'ios') {
              this.$refs.qrcodeAssemblys[0].clear();
            }
          }
          clearInterval(this.longTime);
          this.longTimeNum = 0;
          this.isLongPress = 0;
          break;
        case 'visible':
          // const hiddenEndTime = new Date().getTime();
          // if (hiddenEndTime - this.hiddenStartTime <= this.hide_long_time) {
          //   this.isLongPress = 0;
          //   clearTimeout(this.traceTimer);
          // }
          if (this.dia === 3) {
            this.dia = 1;
          }
          break;
      }
    },
    toSupport() {
      this.$router.push({
        path: '/support'
      });
    },
    html2canvas() {
      const element = this.$refs.picture;
      const that = this;
      // 生成快照
      function convertToImage(container, options = {}) {
        // 设置放大倍数
        const scale = window.devicePixelRatio;
        // 传入节点原始宽高
        const _width = container.offsetWidth;
        const _height = container.offsetHeight;
        let {
          width,
          height
        } = options;
        width = width || _width;
        height = height || _height;

        // html2canvas配置项
        const ops = {
          scale,
          // width,
          // height,
          useCORS: true,
          allowTaint: false,
          ...options
        };
        html2canvas(container, ops).then(canvas => {
          // 返回图片的二进制数据
          that.urls = canvas.toDataURL('image/png');
        }).catch(e => {
          console.log(e);
        });
      }
      // 调用函数，取到截图的二进制数据，对图片进行处理（保存本地、展示等）
      convertToImage(element);
    },
    // html2canvas2() {
    //   // console.log(document.getElementsByClassName("qrcodeAssembly")[0].innerHTML)
    //   var divContent = document.getElementsByClassName("qrcodeAssembly")[0]
    //   console.log(divContent)
    //   setTimeout(()=>{
    //     html2canvas(divContent).then((canvas) => {
    //     const image = canvas.toDataURL('image/png');
    //     console.log(image)
    //     const link = document.createElement('a');
    //     link.href = image;
    //     link.download = 'image.png';
    //     link.click();
    //   });
    //   },1000)
    // },
    getModel() {
      // 判断是在手机还是电脑
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      if (flag != null) {
        if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
          this.modelType = 'ios';
        } else {
          this.modelType = 'android';
        }
      }
    },
    async init() {
      let uid = localStorage.getItem('wechat_uid');
      if (uid != null) this.uid = uid;

      // let copy = 'http://localhost:9301/#/landFront?t=nbhIWVyw&_cl=45EM&tid=6e2b4c9c-69e4-11ec-817d-00163e18a41a';
      // let url = copy.split('?')[1];
      const url = window.location.href.split('?')[1];
      if (!url) return;

      // 判断是外部浏览器还是小程序环境   pageType>0 小程序  pageType<0外部H5环境
      const pageType = url.indexOf('pageType');
      this.pageType = pageType > 0;
      if (this.pageType) {
        setTimeout(() => {
          this.$router.replace({
            path: 'jumpIndex'
          });
        }, 90000);
      }
      if (url.indexOf('&') === -1) {
        return false;
      } else {
        const paramsArr = location.href.split('?')[1].split('&');
        const params = {};
        for (const param of paramsArr) {
          if (param.split('=')[0] != 'biz' && param.split('=')[0] != '_cl' && param.split('=')[0] != 't') params[param.split('=')[0]] = param.split('=')[1];
        }
        this.urlParams = params;
        const arrs = url.split('&');
        for (let i = 0; i < arrs.length; i++) {
          const name = arrs[i].split('=')[0];
          switch (name) {
            case 't':
              this.token = arrs[i].split('=')[1];
              break;
            case '_cl':
              this.channel_param = arrs[i].split('=')[1];
              break;
            case 'adid':
            case 'ad_id':
              this.ad_id = arrs[i].split('=')[1];
              break;
            case 'creativeid':
              this.creative_id = arrs[i].split('=')[1];
              break;
            case 'creativetype':
              this.creative_type = arrs[i].split('=')[1];
              break;
            case 'clickid':
              this.click_id = arrs[i].split('=')[1];
              break;
            case 'biz':
              this.biz = arrs[i].split('=')[1];
              break;
            case 'tid':
              this.tid = arrs[i].split('=')[1];
              this.tids = JSON.parse(JSON.stringify(this.tid));
              break;
            case 'key':
              this.key = arrs[i].split('=')[1];
              break;
            case 'source':
              this.source = arrs[i].split('=')[1];
              break;
            case 'cache':
              this.cache = arrs[i].split('=')[1];
              break;
          }
        }
        if (url.indexOf('key') === -1) {
          // 落地页详情(渲染)
          this.landDetail();
        } else {
          // 预览
          this.preview();
        }
      }
    },
    postCard(row) {
      if (row.type == '8' || row.wholeType == 8) {
        this.showPostCard = true;
      }
    },
    // 预览
    preview() {
      this.isIframe = true;
      const parse = {
        key: this.key
      };
      apis.preview(parse).then(res => {
        const detail = res.data.detail;
        // 页面设置
        const setting = JSON.parse(detail.setting);
        this.setting = setting;
        // 页面策略
        const strategy = JSON.parse(detail.strategy);
        const strategyLists = strategy.strategyLists;
        this.strategyLists = strategyLists;

        // 组件内容
        const content = JSON.parse(detail.content);
        let wechatzhu = true;
        if (content.length) {
          content.forEach((item, index) => {
            item.hrefid = 'href' + index;
            if (item.text == '微信包') {
              this.wechat_package_id = item.setStyle.wechat;
              wechatzhu = false;
            }
          });
        }
        this.contents = content;
        if (wechatzhu && !this.pageType) {
          // 追踪
          this.visit();
        }
        if (!setting.isApplet) {
          // 页面策略配置
          for (let i = 0; i < strategyLists.length; i++) {
            if (strategyLists[i].triggerCondition === '0' || strategyLists[i].triggerCondition === 0) {
              // 访问页面展示
              if (strategyLists[i].contentType === 1 || strategyLists[i].contentType === '1') {
                // 打开弹窗
                if (strategyLists[i].pop !== '') this.arousePop(strategyLists[i].pop);
              }
            }
          }
        }
        // 页面标题
        // document.title = setting.name;
        this.changeDocumentTitle(setting.name);
      });
    },
    // 详情
    async landDetail() {
      const url = window.location.href.split('?')[1];
      const parse = {
        token: this.token,
        ad_id: this.ad_id,
        tid: this.tid,
        uid: this.uid
      };
      if (this.cache != '') {
        parse.cache = this.cache;
      }
      const res = await apis.landDetail(parse);
      this.uid = res.data.uid;
      this.hide_long_time = res.data.hide_long_time;
      this.landingPageId = res.data.id;
      const detail = res.data.detail;
      this.ibrand_id = res.data.ibrand_id || 0;
      this.jump_token = res.data.jump_token;
      // 页面设置
      const setting = JSON.parse(detail.setting);
      this.setting = setting;
      // 页面策略
      const strategy = JSON.parse(detail.strategy);
      const strategyLists = strategy.strategyLists;
      this.strategyLists = strategyLists;
      // 组件内容
      const content = JSON.parse(detail.content);
      let wechatzhu = true;
      if (content.length) {
        content.forEach((item, index) => {
          item.hrefid = 'href' + index;
          if (item.text === '微信包') {
            this.wechat_package_id = item.setStyle.wechat;
            wechatzhu = false;
          }
          if (item.text == '主体') {
            if (res.data.principal_name != '') {
              item.setStyle.principal_name = res.data.principal_name;
            }
            this.principalObj = item;
          }
        });
      }
      this.contents = content;
      // setTimeout(()=>{
      //   this.$refs.qrcodeAssemblys.map((item,index)=>{
      //     console.log(index,item)
      //     this.$refs.qrcodeAssemblys[index].html2canvas2()
      //   })
      // },2000)
      if (wechatzhu && !this.pageType) {
        // 追踪
        await this.visit();
      }
      if (!setting.isApplet) {
        // 页面策略配置
        for (let i = 0; i < strategyLists.length; i++) {
          if (strategyLists[i].triggerCondition === '0' || strategyLists[i].triggerCondition === 0) {
            // 访问页面展示
            if (strategyLists[i].contentType === '1' || strategyLists[i].contentType === 1) {
              // 打开弹窗
              if (strategyLists[i].pop !== '') this.arousePop(strategyLists[i].pop);
            }
          }
        }
      }
      // 页面标题
      // document.title = setting.name;
      this.changeDocumentTitle(setting.name);
      this.onLoad++;
      const flag = this.contents.some(item => item.component === 'qrcode');
      if (flag && !this.pageType && !this.source) {
        return;
      } else {
        this.judgeFu();
      }
    },
    changeDocumentTitle(title) {
      // 钉钉浏览器内部，使用钉钉提供的JSAPI中的接口dd.biz.navigation.setTitle设置导航栏标题
      if (window.navigator.userAgent.includes("DingTalk")) {
        try {
          // 钉钉必须用自己的api更新title
          dd.ready(function () {
            // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
            dd.biz.navigation.setTitle({
              title: title,
              //控制标题文本，空字符串表示显示默认文本
              onSuccess: function (result) {
                // alert(JSON.stringify(result));
              },
              onFail: function (err) {
                console.log(err);
              }
            });
          });
        } catch (error) {
          window.document.title = title;
        }
      } else {
        window.document.title = title;
      }
    },
    // 页面配置 自定义配置 判断是否执行
    judgeFu() {
      if (this.setting.jumpType === 2 || this.setting.jumpType === '2' || this.setting.jumpType === 3 || this.setting.jumpType === '3' || this.setting.jumpType === 4 || this.setting.jumpType === '4' || this.setting.jumpType === 8 || this.setting.jumpType === '8') {
        this.customConfigured(this.setting);
      } else if (Array.isArray(this.strategy_enter) !== true) {
        // 页面策略
        this.handleTactics(this.strategy_enter);
      }
    },
    // 页面配置 自定义配置
    customConfigured(setting) {
      const {
        jumpType,
        wechatKf,
        appletlink,
        appletUrl,
        landsel,
        channel
      } = setting;
      if (jumpType === 2 || jumpType === '2') {
        this.openWechat(appletlink, appletUrl, landsel, channel);
      }
      if (jumpType === 3 || jumpType === '3') {
        this.jumpKf(wechatKf);
      }
      if (jumpType === 4 || jumpType === '4') {
        console.log(setting, 'settingsetting');
        this.jumpCustomerAcquisition(setting);
      }
      console.log(setting, 'ffffffffffffffff');
      if (jumpType === 8 || jumpType === '8') {
        this.jumpAlipayMini(setting.alipay_mini_id);
      }
    },
    // 页面策略
    handleTactics(strategyLists) {
      const {
        triggerCondition,
        showSecond,
        accessLimits,
        addLimits,
        contentType,
        urlLink,
        appletlink,
        appletUrl,
        ground,
        channel,
        wechatKf,
        access_limit,
        add_limit
      } = strategyLists;
      // 判断 重复访问和重复加微
      if (accessLimits.publicShow === 1 || addLimits.publicShow === 1) {
        if (access_limit === 1 || add_limit === 1) {
          if (showSecond) {
            // 进入页面后延迟执行
            setTimeout(() => {
              this.jumpTacticsType(contentType, urlLink, appletlink, appletUrl, ground, channel, wechatKf);
            }, Number(showSecond) * 1000);
          } else {
            //立即执行
            this.jumpTacticsType(contentType, urlLink, appletlink, appletUrl, ground, channel, wechatKf);
          }
        } else {
          // 条件未达成 中断
          return;
        }
      } else if (showSecond) {
        // 进入页面后延迟执行
        setTimeout(() => {
          this.jumpTacticsType(contentType, urlLink, appletlink, appletUrl, ground, channel, wechatKf);
        }, Number(showSecond) * 1000);
      } else {
        //立即执行
        this.jumpTacticsType(contentType, urlLink, appletlink, appletUrl, ground, channel, wechatKf);
      }
    },
    //页面策略 跳转展示内容
    jumpTacticsType(contentType, urlLink, appletlink, appletUrl, ground, channel, wechatKf) {
      console.log(contentType);
      if (contentType === 0 || contentType === '0') {
        // 跳转URL
        if (urlLink !== '') {
          window.location.href = urlLink;
        }
      } else if (contentType === 1 || contentType === '1') {
        //不跳转
        return;
      } else if (contentType === 2 || contentType === '2') {
        //跳转小程序
        this.openWechat(appletlink, appletUrl, ground, channel);
      } else {
        //跳转客服
        this.jumpKf(wechatKf);
      }
    },
    handleCanvas() {
      this.onLoad++;
    },
    async userwechatid(id) {
      this.user_wechat_id = id;
      await this.visit();
      this.judgeFu();
    },
    async visit() {
      const paramsArr = location.href.split('?')[1].split('&');
      const params = {};
      for (const param of paramsArr) {
        params[param.split('=')[0]] = param.split('=')[1];
      }
      const parse = {
        ...params,
        token: this.token,
        ad_id: this.ad_id,
        creative_id: this.creative_id,
        creative_type: this.creative_type,
        click_id: this.click_id,
        channel_param: this.channel_param,
        wechat_package_id: this.wechat_package_id || undefined,
        user_wechat_id: this.user_wechat_id || undefined,
        uid: this.uid || undefined,
        jump_token: this.jump_token
      };
      const tid = this.$route.query.tid;
      if (tid) {
        parse.tid = tid;
      }
      const res = await apis.visit(parse);
      this.strategy_enter = res.data.strategy_enter || [];
      const data = res.data;
      this.tid = data.tid;
      localStorage.setItem('wechat_uid', data.uid);
    },
    // 网页跳转
    openUrlLink(url) {
      let href;
      if (url.indexOf('?') !== -1 && url.indexOf('=') !== -1) {
        href = `${url}&tid=${this.tid}`;
      } else {
        href = `${url}?tid=${this.tid}`;
      }
      window.location.href = href;
      this.linkUrl = href;
    },
    jumpKf(wechatKf) {
      const params = {
        kf_id: wechatKf,
        tid: this.tid
      };
      apis.getWechatKfUrl(params).then(res => {
        if (res.code == '200' && typeof res.data.ab_limit != 'undefined' && res.data.ab_limit == 1) {
          this.blackShow();
          return;
        }
        if (res.data.url) {
          window.location.href = res.data.url;
          this.linkUrl = res.data.url;
        }
      });
    },
    jumpAlipayMini(alipayMiniId) {
      const params = {
        apmi: alipayMiniId,
        tid: this.tid
      };
      apis.getAlipayMiniUrl(params).then(res => {
        if (res.code == '200' && typeof res.data.ab_limit != 'undefined' && res.data.ab_limit == 1) {
          this.blackShow();
          return;
        }
        if (res.data.url) {
          window.location.href = res.data.url;
          this.linkUrl = res.data.url;
        }
      });
    },
    scrollBottom() {
      // let middle = this.$refs['picture'];
      $("html, body").stop();
      this.$nextTick(() => {
        // middle.scrollTop = middle.scrollHeight;
        setTimeout(() => {
          let middle = $("html, body")[0];
          $("html, body").animate({
            scrollTop: middle.scrollHeight
          }, 3000, "linear");
          setTimeout(() => {
            $("html, body").stop();
          }, 1000);
        }, 1000);
      });
    },
    closeComPopup() {
      this.$refs.comPopup && this.$refs.comPopup.close();
    },
    jumpCustomerAcquisition(row) {
      // 获客助手跳转
      // var urlqz = 'weixin://biz/ww/profile/'
      // console.log(row.customerAcquisitionUrl)
      // var urlhz=row.customerAcquisitionUrl + '?customer_channel=' +this.tid +'.' +   localStorage.getItem('wechat_uid')
      // var str = row.customerAcquisitionUrl.split("/")
      // var targetStr = str[str.length-1]
      // if(row.link_id){
      //   urlhz += '.' + row.link_id
      // }else{
      //   urlhz += '.' + targetStr
      // }
      // var url = urlqz+encodeURIComponent(urlhz)
      // window.location.href = url
      console.log(row, "row");
      if (row.postCard) {
        this.currentRow = {
          ...row
        };
        this.showPostCard = true;
      } else {
        let params = {
          uid: this.uid,
          tid: this.tid
        };
        if (typeof row.wechat_package_id != 'undefined') {
          params.wechat_package_id = row.wechat_package_id;
        }
        if (typeof row.link_id != 'undefined') {
          params.link_id = row.link_id;
        }
        apis.acquisitionScheme(params).then(res => {
          if (res.code == '200' && typeof res.data.ab_limit != 'undefined' && res.data.ab_limit == 1) {
            this.blackShow();
            return;
          }
          if (res.data.acquisition_url != '') {
            console.log(res, '获客助手接口', decodeURIComponent(res.data.acquisition_url));
            window.location.href = decodeURIComponent(res.data.acquisition_url);
            this.linkUrl = decodeURIComponent(res.data.acquisition_url);
          }
        });
      }
    },
    linkUrlDel() {
      this.linkUrl = '';
    },
    arousePop(widget_template_id) {
      // 小工具模版详情
      const parse = {
        widget_template_id: widget_template_id
      };
      apis.widgetTemplate(parse).then(res => {
        this.popcontent = JSON.parse(res.data.content);
        this.popsetting = JSON.parse(res.data.setting);
        // 显示弹窗
        this.$refs.comPopup.bool = true;
        this.$refs.comPopup.iszhezhao = true;
      });
    },
    // 跳转微信小程序
    openWechat(applet_link, open_link, landing_page_id, channel_id) {
      if (applet_link && applet_link === 1) {
        const parse = {
          tid: this.tid,
          open_link: open_link // 落地页公众号链接
        };

        if (this.tid == '') {
          return;
        }
        // this.html2canvas()
        apis.generateOfficial(parse).then(res => {
          if (res.data.url_scheme) {
            if (this.modelType === 'ios') {
              location.href = res.data.url_scheme;
              this.linkUrl = res.data.url_scheme;
            } else {
              window.location.href = res.data.url_scheme;
              this.linkUrl = res.data.url_scheme;
            }
          }
          if (res.data.tid) {
            this.tid = res.data.tid;
          }
        });
        return;
      }
      const parse = {
        tid: this.tid,
        landing_page_id: landing_page_id ? landing_page_id : applet_link,
        // 落地页id
        channel_id: channel_id ? channel_id : open_link // 落地页渠道id
      };
      // this.html2canvas()
      apis.generateScheme(parse).then(res => {
        if (res.code == 200) {
          if (typeof res.data.ab_limit != 'undefined' && res.data.ab_limit == 1) {
            this.blackShow();
            return;
          }
          if (res.data.url_scheme) {
            if (this.modelType === 'ios') {
              location.href = res.data.url_scheme;
              this.linkUrl = res.data.url_scheme;
            } else {
              // 添加异步+时间戳解决可能缓存带来的跳转无效问题
              // setTimeout(()=>{
              window.location.href = res.data.url_scheme + '&a=' + new Date().getTime();
              this.linkUrl = res.data.url_scheme + '&a=' + new Date().getTime();
              // },100)
            }
          }

          if (res.data.tid) {
            this.tid = res.data.tid;
          }
        }
      });
    },
    blackShow() {
      this.name = '';
      this.mobile = '';
      this.isBlackInputShow = true;
    },
    // 长按事件
    start() {
      this.startTime = new Date().getTime();
    },
    // 微信环境底下 ios touchend 可以触发
    end() {
      if (this.source == 'official_kf') {
        this.trace(53);
      }
    },
    // 微信环境下 安卓 touchcancel 可以触发
    cancel() {
      if (this.source == 'official_kf') {
        this.trace(53);
      }
      // this.handleTrace40(false);
    },

    // 小程序追踪
    trace(step) {
      console.log(step, 'stepstep');
      // if (!this.pageType) return false;
      const parse = {
        tid: step == 53 ? this.tids : this.tid,
        step: step
      };
      apis.trace(parse).then(res => {});
    },
    isWeixin() {
      let ua = navigator.userAgent.toLowerCase();
      return ua.indexOf('micromessenger') != -1;
    },
    handleTrace40(checkTime) {
      if (this.isWeixin() && this.wechat_package_id > 0 && this.trace40 === 0) {
        if (true === checkTime) {
          const endTime = new Date().getTime();
          if (endTime - this.startTime < 1500) {
            return;
          }
        }
        this.trace40 = 1;
        this.trace(40);
        this.isLongPress = 1;
      }
    },
    touchStart(e) {
      // 模拟长按
      clearTimeout(this.loop); // 再次清空定时器，防止重复注册定时器（会把点击事件也阻止掉）
      this.loop = setTimeout(() => {
        this.longPress();
      }, 1000);
    },
    touchend() {
      clearTimeout(this.loop); // 清空定时器，防止重复注册定时器
    },

    //真正长按后应该执行的内容
    longPress() {
      console.log('长按了');
      this.trace40 = 1;
      clearInterval(this.longTime);
      this.longTimeNum = 0;
      this.isLongPress = 1;
      const parse = {
        tid: this.tid,
        step: "40"
      };
      apis.trace(parse).then(res => {});
      this.longTime = setInterval(() => {
        ++this.longTimeNum;
        this.isLongPress = 1;
        if (this.longTimeNum >= this.hide_long_time / 1000) {
          console.log(this.longTimeNum);
          clearInterval(this.longTime);
          this.isLongPress = 0;
          this.longTimeNum = 0;
        }
      }, 1000);
    }
  }
};