import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-71fd0891"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = ["href"];
const _hoisted_4 = ["src"];
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  key: 1
};
const _hoisted_7 = ["href"];
const _hoisted_8 = ["src"];
const _hoisted_9 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "picture",
    style: _normalizeStyle({
      'margin-top': $props.content.topMargin + 'px',
      'margin-left': $props.content.leftMargin + 'px',
      'margin-right': $props.content.rightMargin + 'px',
      'margin-bottom': $props.content.bottomMargin + 'px'
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.content.imageList, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "list",
      key: index,
      onClick: $event => $options.openpage(item)
    }, [$props.content.linkType == '0' ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$props.content.wholeType == '1' ? (_openBlock(), _createElementBlock("a", {
      key: 0,
      href: $props.content.href
    }, [_createElementVNode("img", {
      class: "img",
      src: item.img,
      alt: ""
    }, null, 8, _hoisted_4)], 8, _hoisted_3)) : (_openBlock(), _createElementBlock("img", {
      key: 1,
      class: "img",
      src: item.img,
      alt: ""
    }, null, 8, _hoisted_5))])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [item.type == '1' ? (_openBlock(), _createElementBlock("a", {
      key: 0,
      href: '#' + item.href
    }, [_createElementVNode("img", {
      class: "img",
      src: item.img,
      alt: ""
    }, null, 8, _hoisted_8)], 8, _hoisted_7)) : (_openBlock(), _createElementBlock("img", {
      key: 1,
      class: "img",
      src: item.img,
      alt: ""
    }, null, 8, _hoisted_9))]))], 8, _hoisted_1);
  }), 128)), $props.content.describe != '' ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "describe",
    style: _normalizeStyle({
      'font-size': $props.content.describeSize + 'px',
      color: $props.content.describeColor,
      'justify-content': $props.content.flex
    })
  }, _toDisplayString($props.content.describe), 5)) : _createCommentVNode("", true)], 4);
}